@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
* {
  margin: 0;
  scroll-behavior: smooth;
  font-family:"League Spartan";
  min-width: 0;
  
}
html {
  height: 100%;
  overflow:auto

}

body {
  height: 100%;
}
form input {
  margin-bottom: 1rem;
  height: 2.5rem;
  border-radius: 9999px;
  padding-left: 10px;
}
form textarea {
  height: 15rem;
  border-radius: 20px;
  padding-left: 10px;
}
form button {
  background-color: rgb(251, 251, 251);
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;

  width:100px;

}

.scrollingEffect {
  animation: scroll 10s linear infinite; /* Adjust the animation duration as needed */
}
.fade{
  position: absolute;
  inset: 0;
  pointer-events: none;
}
@keyframes scroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%);
  }
}

